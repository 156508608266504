/* global $ */
import React from 'react'
import * as fabric from 'fabric'

class SeeOnMyHousePreviewMobile extends React.Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()
  }

  componentDidMount() {
    this.canvasRender()
  }

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve({ height: img.height, width: img.width })
      img.onerror = reject
      img.src = src
      console.log(img.height, img.width)
      return img
    })
  }

  canvasRender = () => {
    var myImg = document.querySelector('#background-mobile')
    this.addImageProcess(myImg.getAttribute('src'))
      .then((response) => {
        var realWidth = myImg.clientWidth //myImg.naturalWidth;
        var realHeight = response.height * (realWidth / response.width) //myImg.naturalHeight;

        let canvas = new fabric.Canvas('my-door-mobile', { selection: true })
        // fabric.textureSize = 3000
        canvas.on('object:scaling', onObjectScaled)

        function onObjectScaled(e) {
          var scaledObject = e.target
        }
        canvas.setDimensions({ width: realWidth, height: realHeight })

        fabric.Image.fromURL(
          this.props.image,
          function (img) {
            img.set({
              left: 0,
              top: 0,
              selectable: true,
              lockScalingX: false,
              lockScalingY: false,
            })
            img.scaleToHeight(100)
            img.scaleToWidth(100)
            canvas.add(img)

            // canvas.renderAll();
          },

          {
            crossOrigin: 'anonymous',
          }
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  changeBackground = (e) => {
    let files = e.target.files
    // console.log(files)
    if (files.length > 0) {
      let url = URL.createObjectURL(files[0])
      document.getElementById('background-mobile').src = url
      this.props.setMyDoorEditor(true)
    }
  }

  render() {
    return (
      <div
        className="summary__img summary-preview gallery mobile"
        id={'html2png'}
      >
        <div className="summary__img-box">
          <img
            id={'background-mobile'}
            crossOrigin="anonymous"
            src={this.props.activeHouse.image}
            alt=""
          />
          {/* {!this.props.myDoorEditor ? (
            <div className="summary-preview-gallery__popup">
              <div className="summary-preview-gallery__popup-inner">
                <span className="global-btn global-btn-arrow" type="button">
                  <span>UPLOAD YOUR HOUSE PHOTO</span>
                  <input
                    type={'file'}
                    id={'load_image'}
                    onChange={this.changeBackground}
                    name={'image'}
                  />
                </span>
                <button
                  className="global-btn global-btn-arrow"
                  type="button"
                  onClick={() => {
                    this.props.setMyDoorEditor(true)
                  }}
                >
                  START WITH THIS PHOTO
                </button>
              </div>
            </div>
          ) : (
            <>
              <img
                src={this.props.image}
                id={'canvas_image'}
                style={{ display: 'none', width: 3000, height: 3000 }}
              />
              <canvas id={'my-door-mobile'} ref={this.canvasRef} />
            </>
          )} */}
        </div>
      </div>
    )
  }
}

export default SeeOnMyHousePreviewMobile
