import React from 'react'
import * as fabric from 'fabric'
import SeeOnMyHouseBottpanelControl from './SeeOnMyHouseBottpanelControl'

class SeeOnMyHousePreview extends React.Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.myDoorEditor && !prevProps.myDoorEditor) {
      this.canvasRender()
    }
  }

  componentWillUnmount() {}

  canvasRender = () => {
    var myImg = document.querySelector('#background')
    // console.log(myImg.clientWidth, myImg.clientHeight);
    var realWidth = myImg.clientWidth //myImg.naturalWidth;
    var realHeight = myImg.clientHeight //myImg.naturalHeight;
    let canvas = new fabric.Canvas('my-door', { selection: true })
    // fabric.textureSize = 3000
    canvas.clear()
    canvas.on('object:scaling', onObjectScaled)
    function onObjectScaled(e) {
      var scaledObject = e.target
    }
    canvas.setDimensions({ width: realWidth, height: realHeight })
    fabric.Image.fromURL(
      this.props.image,
      function (img) {
        img.set({
          left: 10,
          top: 10,
          selectable: true,
          lockScalingX: false,
          lockScalingY: false,
        })
        img.scaleToHeight(400)
        img.scaleToWidth(400)
        canvas.add(img)
        // canvas.renderAll();
      },
      {
        crossOrigin: 'anonymous',
      }
    )
  }

  changeBackground = (e) => {
    let files = e.target.files
    // console.log(files)
    if (files.length > 0) {
      let url = URL.createObjectURL(files[0])
      document.getElementById('background').src = url
      this.props.setMyDoorEditor(true)
    }
  }

  render() {
    return (
      <div
        className={
          'summary__img summary-preview gallery summary__img-box-zoom ' +
          ' ' +
          (this.props.classAdd !== undefined ? this.props.classAdd : '')
        }
      >
        <div className="summary__img-box " id={'html2png'}>
          <img
            id={'background'}
            crossOrigin="anonymous"
            src={this.props.activeHouse.image}
            style={{ transform: `scale(${this.props.zoom})` }}
            alt=""
          />

          <div
            className="summary-preview-gallery__popup"
            style={{ display: !this.props.myDoorEditor ? 'flex' : 'none' }}
          >
            <div className="summary-preview-gallery__popup-inner">
              <span className="global-btn global-btn-arrow" type="button">
                <span>UPLOAD YOUR HOUSE PHOTO</span>
                <input
                  type={'file'}
                  id={'load_image'}
                  onChange={this.changeBackground}
                  name={'image'}
                />
              </span>
              <button
                className="global-btn global-btn-arrow"
                type="button"
                onClick={() => {
                  this.props.setMyDoorEditor(true)
                }}
              >
                START WITH THIS PHOTO
              </button>
            </div>
          </div>

          <div style={{ display: !this.props.myDoorEditor ? 'none' : 'block' }}>
            <img
              src={this.props.image}
              id={'canvas_image'}
              style={{ display: 'none', width: 3000, height: 3000 }}
            />
            <canvas
              id={'my-door'}
              ref={this.canvasRef}
              style={{ transform: `scale(${this.props.zoom})` }}
            />
          </div>
        </div>

        <SeeOnMyHouseBottpanelControl
          myDoorEditor={this.props.myDoorEditor}
          setSeeMyDoor={this.props.setSeeMyDoor}
          setMyDoorEditor={this.props.setMyDoorEditor}
          hideSeeMyDoor={this.props.hideSeeMyDoor}
          plusZoom={this.props.plusZoom}
          minusZoom={this.props.minusZoom}
        />
      </div>
    )
  }
}

export default SeeOnMyHousePreview
