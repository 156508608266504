/* global $ */
import React, { Component } from 'react'
import ConsumerFlowConstructor from './ConsumerFlow/ConsumerFlowConstructor/ConsumerFlowConstructor'
import {
  Canvas,
  FabricImage,
  FabricObject,
  util,
  filters,
  Pattern,
  StaticCanvas,
  WebGLFilterBackend,
} from 'fabric'
import { connect } from 'react-redux'
import { getFurnitureCategories } from '../redux/actions/categories'
import {
  getFurnitureParts,
  getHandelBrands,
  getHandelsByModel,
  getGlassByModel,
  getFrameGlasses,
  getPartsOfBrand,
  getFurnitureGroup,
  getDefaultModelBrand,
} from '../redux/actions/parts'
import { showAffiliate } from './../redux/actions/affiliate'
import { saveOrder, retrieveOrder } from '../redux/actions/orders'
import { getAllHouses } from '../redux/actions/houses'
import Loader from './Loader/Loader'
import SeeOnMyHouse from './SeeOnMyHouse/SeeOnMyHouse'
import { toPng } from 'html-to-image'
import {
  getInsideDoorColor,
  getInsideFrameColor,
  getOutsideDoorColor,
  getOutsideFrameColor,
  getDefaultColorsRequest,
} from './../redux/actions/colors'
import { getBrand } from '../redux/actions/brands'

class AbstractConstructor extends Component {
  state = {
    DimensionsPopupShow: false,
    brands: [],
    page: 'door_style',
    direction: 'next',
    currentBrand: {},
    mobile_window_width: 1200,
    model: [],
    parts: [],
    modelColors: [],
    brand: 'silka',
    order: 'external',
    furniture: [],
    furnitureParts: [],
    furnitureGroups: [],
    formData: {
      name: '',
      address_number_1: '',
      address_number_2: '',
      post_code: '',
      phone_number: '',
      email: '',
      county: '',
      coupon: '',
      door_style: null,
      frame_style: null,
      glass: null,
      handle: null,
      letterplate: null,
      knocker: null,
      outside_door_color: null,
      inside_door_color: null,
      outside_frame_color: null,
      inside_frame_color: null,
      brand: null,
      door_glass: null,
      frame_glass_top: null,
      frame_glass_side: null,
      type_constructor: null,
      wreath: null,
      side: 'right',
      image: '',
      handelBrand: '',
    },
    is_loader: true,
    seeMyDoor: false,
    myDoorEditor: false,
    navLinkStep: 0,
    glasses: [],
    handelBrands: [],
    activeHandleBrand: {},
    handles: [],
    knockers: [],
    wreaths: [],
    letterplates: [],
    frameGlasses: [],
    houses: [],
    activeHouse: {},
    scale: 1,
    backgroundColor: 'transparent',
    showImageMobile: false,
    brandTitle: '',
    glass_code: '',
    isHandleBrandError: false,
    isHandleError: false,
    affiliate: {},
    outsideDoorColours: [],
    insideDoorColours: [],
    outsideFrameColours: [],
    insideFrameColours: [],
  }

  constructor(props) {
    super(props)
    let widthCanvas = window.innerWidth > 1200 ? 2000 : 550
    let heightCanvas = window.innerWidth > 1200 ? 2000 : 550
    this.state.widthCanvas = widthCanvas
    this.state.heightCanvas = heightCanvas

    const webglBackend = new WebGLFilterBackend()
    this.state.canvas = new Canvas('canvas-builder', {
      selection: false,
      filterBackend: webglBackend,
      width: widthCanvas,
      height: heightCanvas,
      enableRetinaScaling: false,
    })
  }
  componentDidMount() {
    if (
      this.props.match.path ===
      '/admin-constructor/:brand/(partner-id)?/:iframePartnerPath?'
    ) {
      this.props
        .getBrand(
          this.props.match.params.brand,
          this.props.match.params.iframePartnerPath
        )
        .then((response) => {
          this.setState({
            brandTitle: response.data.name,
          })
        })

      this.props
        .getPartsOfBrand(this.props.match.params.brand)
        .then((response) => {
          if (response.data.success === true) {
            this.setParts(response.data.data)
            let brand = this.getBrandDoor(response.data.data)
            this.props
              .getDefaultModelBrand(brand)
              .then((response) => {
                let models = [...response.data.data.models]
                let modelColors = [...response.data.data.modelColors]
                let door = models.find(
                  (item) => item.category_part_slug === 'door_style'
                )
                let frame = models.find(
                  (item) => item.category_part_slug === 'frame_style'
                )
                let outsideDoorColour = modelColors.find(
                  (item) =>
                    item.order === 'external' &&
                    item.partCategory === 'door_style'
                )
                let outsideFrameColour = modelColors.find(
                  (item) =>
                    item.order === 'external' &&
                    item.partCategory === 'frame_style'
                )
                let insideDoorColour = modelColors.find(
                  (item) =>
                    item.order === 'internal' &&
                    item.partCategory === 'door_style'
                )
                let insideFrameColour = modelColors.find(
                  (item) =>
                    item.order === 'internal' &&
                    item.partCategory === 'frame_style'
                )

                this.setState({
                  model: [...response.data.data.models],
                  modelColors: [...response.data.data.modelColors],
                  brand: brand,
                  formData: {
                    ...this.state.formData,
                    door_style: door.id,
                    frame_style: frame.id,
                    outside_door_color: outsideDoorColour.color.id,
                    inside_door_color: insideDoorColour.color.id,
                    outside_frame_color: outsideFrameColour.color.id,
                    inside_frame_color: insideFrameColour.color.id,
                    brand: this.props.match.params.brand,
                  },
                })
              })
              .catch((error) => console.log(error))
          }
        })

      this.props
        .showAffiliate(this.props.match.params.iframePartnerPath)
        .then((response) => {
          this.setState({ affiliate: { ...response.data } })
        })
    }

    this.loadAffiliate()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.model !== prevState.model ||
      this.state.modelColors !== prevState.modelColors ||
      this.state.order !== prevState.order ||
      this.state.formData.side !== prevState.formData.side
    ) {
      this.renderCanvas()
    }

    const img = document.querySelectorAll('.build__item-img img')

    for (var i = 0; i < img.length; i++) {
      img[i].addEventListener('error', function (event) {
        const target = event.target

        target.parentNode.parentNode.style.display = 'none'
      })
    }
  }

  componentWillUnmount() {
    // window.document.getElementById("canvas-cont").innerHTML = ``;
  }

  limitSize(size, maximumPixels) {
    const { width, height } = size
    const requiredPixels = width * height

    if (requiredPixels <= maximumPixels) return { width, height }

    const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels)

    return {
      width: Math.floor(width * scalar),
      height: Math.floor(height * scalar),
    }
  }

  loadAffiliate = () => {
    let path = this.props.match.params.iframePartnerPath
    if (!!path) {
      this.props.showAffiliate(path).then((response) => {
        this.setState({
          affiliate: { ...response.data },
        })
      })
    }
  }

  loadRetrieveOrder = (id) => {
    this.props.retrieveOrder(id).then((response) => {
      this.setState({
        model: [...response.data.models],
        formData: {
          ...response.data.formData,
        },
        modelColors: [...response.data.modelColors],
        activeHandleBrand: { ...response.data.additions.handleBrand },
        handles: [...response.data.additions.handles],
      })

      this.props
        .getPartsOfBrand(response.data.formData.brand)
        .then((response) => {
          if (response.data.success === true) {
            this.setParts(response.data.data)
          }
        })
    })
  }

  loadOutsideDoorColors = () => {
    let door = this.findLayer('door_style')
    this.props
      .getOutsideDoorColor(door.id, door.brand)
      .then((response) => {
        this.setState((state) => {
          return { ...state, outsideDoorColours: [...response.data] }
        })
      })
      .catch((error) => console.log(error))
  }

  loadDefaultOutsideDoorColor = async (component) => {
    let door = component
    let frame = this.findLayer('frame_style')
    let brand = component.brand

    let response = await this.props.getDefaultColorsRequest(
      door.id,
      frame.id,
      brand
    )
    return response
  }

  loadInsideDoorColors = () => {
    let color = this.state.modelColors.find(
      (item) => item.order === 'external' && item.partCategory === 'door_style'
    )
    let brand = this.getBrandDoor(this.state.model)

    this.props
      .getInsideDoorColor(color.color.outside_colours_code, brand)
      .then((response) => {
        this.setState((state) => {
          return {
            ...state,
            insideDoorColours: [...response.data],
          }
        })
      })
      .catch((error) => console.log(error))
  }

  loadOutsideFrameColors = () => {
    let frame = this.findLayer('frame_style')
    let brand = this.getBrandDoor(this.state.model)
    this.props
      .getOutsideFrameColor(frame.id, brand)
      .then((response) => {
        this.setState((state) => {
          return { ...state, outsideFrameColours: [...response.data] }
        })
      })
      .catch((error) => console.log(error))
  }

  loadInsideFrameColors = () => {
    let color = this.state.modelColors.find(
      (item) => item.order === 'external' && item.partCategory === 'frame_style'
    )
    let brand = this.getBrandDoor(this.state.model)
    this.props
      .getInsideFrameColor(color.color.outside_colours_code, brand)
      .then((response) => {
        this.setState({
          ...this.state,
          insideFrameColours: [...response.data],
        })
      })
      .catch((error) => console.log(error))
  }

  loadHandelBrands = (brand) => {
    this.props
      .getHandelBrands(brand, this.state.formData.door_style)
      .then((response) => {
        this.setState({
          handelBrands: [...response.data],
        })
      })
  }

  loadGlassByModel = () => {
    let frame = this.findLayer('frame_style')
    let door = this.findLayer('door_style')
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.getGlassByModel(door.code, frame.code).then((response) => {
          if (response.data.length > 0) {
            this.setState({
              glasses: [...response.data],
              is_loader: false,
            })
          } else {
            if (this.state.direction === 'next') {
              this.setPage('furniture-type-handle')
            } else {
              this.setPage('inside-frame-color', 'back')
            }
          }
        })
      }
    )
  }

  loadHandlesByModal = (model) => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.getHandelsByModel(model).then((response) => {
          this.setState(
            {
              handles: [...response.data],
              is_loader: false,
            },
            () => {
              if ($(window).width() <= 500) {
                this.setPage('furniture-color-handle')
              }
            }
          )
        })
      }
    )
  }

  loadFrameGlasses = () => {
    let frame = this.state.model.find(
      (item) => item.category_part_slug === 'frame_style'
    )
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.getFrameGlasses(frame.code).then((response) => {
          this.setState({
            frameGlasses: [...response.data],
            is_loader: false,
          })
        })
      }
    )
  }

  loadKnockers = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props
          .getFurnitureParts('knocker', 0, this.state.formData.door_style)
          .then((response) => {
            if (response.data.length > 0) {
              this.setState({
                knockers: [...response.data],
                is_loader: false,
              })
            } else {
              if (this.state.direction === 'next') {
                this.setPage('furniture-type-letterplate')
              } else {
                this.setPage('furniture-type-knocker', 'back')
                this.setLoader(false)
              }
            }
          })
      }
    )
  }

  loadLetterplates = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props
          .getFurnitureParts('letterplate', 0, this.state.formData.door_style)
          .then((response) => {
            if (response.data.length > 0) {
              this.setState({
                letterplates: [...response.data],
                is_loader: false,
              })
            } else {
              if (this.state.direction === 'next') {
                this.setPage('furniture-side-hinges')
              } else {
                this.setPage('furniture-wreath', 'back')
              }
            }
          })
      }
    )
  }

  loadWreaths = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props
          .getFurnitureParts('wreath', 0, this.state.formData.door_style)
          .then((response) => {
            if (response.data.length > 0) {
              this.setState({
                wreaths: [...response.data],
                is_loader: false,
              })
            } else {
              if (this.state.direction === 'next') {
                this.setPage('furniture-type-knocker')
              } else {
                this.setPage('furniture-color-handle', 'back')
              }
            }
          })
      }
    )
  }

  loadLeftOrRight = (model) => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        if (!!model.disable_change_side) {
          this.setPage('my-dream-door')
        }
      }
    )

    this.setLoader(false)
  }

  loadHouses = () => {
    this.setState(
      {
        is_loader: true,
      },
      () => {
        this.props.getAllHouses().then((response) => {
          let activeHouse = response.data.length > 0 ? response.data[0] : {}
          this.setState({
            houses: [...response.data],
            activeHouse,
            is_loader: false,
          })
        })
      }
    )
  }
  loadFurnitureGroup = () => {
    let brand = this.getBrandDoor(this.state.model)

    this.props.getFurnitureGroup(brand).then((response) => {
      this.setState({
        furnitureGroups: [...response.data],
      })
    })
  }

  getDefaultModel = (parts) => {
    let doorStyle = parts.find(
      (part) => part.category_part_slug === 'door_style'
    )
    let frameStyle = parts.find(
      (part) => part.category_part_slug === 'frame_style'
    )
    let models = []
    if (doorStyle !== undefined) {
      models.push(doorStyle)
    }
    if (frameStyle !== undefined) {
      models.push(frameStyle)
    }

    let modelColorsDoor = this.getDefaultColors(doorStyle)
    let modelColorsFrame = this.getDefaultColors(frameStyle)

    let modelColors = modelColorsDoor.concat(modelColorsFrame)
    let outsideDoorColour = modelColorsDoor.find(
      (color) => color.order === 'external'
    )
    let insideDoorColour = modelColorsDoor.find(
      (color) => color.order === 'internal'
    )
    let outsideFrameColour = modelColorsFrame.find(
      (color) => color.order === 'external'
    )
    let insideFrameColour = modelColorsFrame.find(
      (color) => color.order === 'internal'
    )

    return {
      model: models,
      modelColors,
      formData: {
        ...this.state.formData,
        door_style: doorStyle !== undefined ? doorStyle.id : null,
        frame_style: frameStyle !== undefined ? frameStyle.id : null,
        outside_door_color:
          modelColorsDoor.length > 0 ? outsideDoorColour.color.id : null,
        inside_door_color:
          modelColorsDoor.length > 0 ? insideDoorColour.color.id : null,
        outside_frame_color:
          modelColorsFrame.length > 0 ? outsideFrameColour.color.id : null,
        inside_frame_color:
          modelColorsFrame.length > 0 ? insideFrameColour.color.id : null,
      },
    }
  }

  saveOrder = () => {
    let formData = { ...this.state.formData }
    formData.brand = this.getBrandDoor(this.state.model)
    this.setState(
      {
        is_loader: true,
      },
      () => {
        formData.brand = this.props
          .saveOrder(formData, this.props.match.params.iframePartnerPath)
          .then((response) => {
            this.props.history.push(
              `/thank-you/${response.data.data.order_id}/${
                !!this.props.match.params['iframePartnerPath']
                  ? this.props.match.params['iframePartnerPath']
                  : ''
              }`
            )
          })
      }
    )
  }

  DimensionsPopupShowHandlerShow = (brand) => {
    this.setState({
      DimensionsPopupShow: true,
      currentBrand: { ...brand },
    })
  }

  DimensionsPopupShowHandlerHide = () => {
    this.setState({
      DimensionsPopupShow: false,
    })
  }

  filterParts = (page) => {
    let parts = this.state.parts.filter((part) => {
      return part.category_part_slug === page
    })
    return parts
  }

  confirmPopupDimensions = () => {
    this.setState({
      page: 'door_style',
    })
  }

  skipPopupDimensions = () => {
    this.setState({
      page: 'door_style',
    })
  }

  clearBrand = () => {
    this.setState({
      currentBrand: {},
    })
  }

  getBrandDoor = (parts) => {
    let i, brand
    for (i = 0; i < parts.length; i++) {
      if (parts[i].category_part_slug === 'door_style') {
        brand = parts[i].brand
        break
      }
    }
    return brand
  }

  navigateConstructorNav = (page, sidebar = false) => {
    this.setState({
      page: page,
      showImageMobile: false,
    })
  }
  chooseComponent = async (component, options = {}) => {
    let model = [...this.state.model]
    let brand = this.getBrandDoor(this.state.model)
    let index = model.findIndex(
      (modelItem) =>
        modelItem.category_part_slug === component.category_part_slug
    )

    component.options = options
    if (index === -1) {
      model.push(component)
    } else {
      model[index] = component
    }
    let formData = { ...this.changeComponentFormData(component) }
    let modelColor = [...this.state.modelColors]
    let page = this.state.page
    if (
      component.category_part_slug === 'door_style' ||
      component.category_part_slug === 'frame_style'
    ) {
      if (component.category_part_slug === 'door_style') {
        let colors = await this.loadDefaultOutsideDoorColor(component)
        let oldFrame = model.find(
          (part) => part.category_part_slug === 'frame_style'
        )
        let newFrame = this.state.parts.find(
          (part) =>
            part.category_part_slug === 'frame_style' &&
            part.brand === component.brand &&
            part.code === oldFrame.code
        )
        let indexFrame = model.findIndex(
          (modelItem) => modelItem.category_part_slug === 'frame_style'
        )
        model[indexFrame] = newFrame
        modelColor = [...colors.data]
        brand = component.brand
      }

      // modelColor = this.getDefaultColors(component)
      if ($(window).width() <= 500) {
        page = page === 'door_style' ? 'frame_style' : 'outside-door-color'
      }
      model = this.deleteCategoryFromModel(model, 'frame-glasses-top')
      model = this.deleteCategoryFromModel(model, 'frame-glasses-side')
      model = this.deleteCategoryFromModel(model, 'door-glasses')
      formData.glass = null
    }

    let outsideDoorColour = modelColor.find(
      (color) =>
        color.order === 'external' && color.partCategory === 'door_style'
    )
    let insideDoorColour = modelColor.find(
      (color) =>
        color.order === 'internal' && color.partCategory === 'door_style'
    )
    let outsideFrameColour = modelColor.find(
      (color) =>
        color.order === 'external' && color.partCategory === 'frame_style'
    )
    let insideFrameColour = modelColor.find(
      (color) =>
        color.order === 'internal' && color.partCategory === 'frame_style'
    )

    formData = {
      ...formData,
      door_glass: null,
      frame_glass_top: null,
      frame_glass_side: null,
      outside_door_color: outsideDoorColour.color.id ?? null,
      inside_door_color: insideDoorColour.color.id ?? null,
      outside_frame_color: outsideFrameColour.color.id ?? null,
      inside_frame_color: insideFrameColour.color.id ?? null,
    }

    this.setState({
      model: [...model],
      formData: {
        ...formData,
      },
      modelColors: [...modelColor],
      is_loader: true,
      showImageMobile: true,
      page,
      brand: brand,
    })
  }

  deleteCategoryFromModel = (model, category) => {
    let index = model.findIndex(
      (modelItem) => modelItem.category_part_slug === category
    )
    if (index !== -1) {
      model.splice(index, 1)
    }
    return model
  }

  getDefaultColors = (component) => {
    if (component === undefined) {
      return []
    }
    let modelColors = [...this.state.modelColors]

    let externalIndex = modelColors.findIndex(
      (color) =>
        color.partCategory === component.category_part_slug &&
        color.order === 'external'
    )
    if (externalIndex !== -1) {
      modelColors.splice(externalIndex, 1)
    }
    let internalIndex = modelColors.findIndex(
      (color) =>
        color.partCategory === component.category_part_slug &&
        color.order === 'internal'
    )
    if (internalIndex !== -1) {
      modelColors.splice(internalIndex, 1)
    }
    if (
      component.external_colours !== null &&
      component.external_colours.length > 0
    ) {
      modelColors.push({
        order: 'external',
        partCategory: component.category_part_slug,
        color: component.external_colours[0],
      })
      if (
        component.external_colours !== null &&
        component.external_colours[0].internal.length > 0
      ) {
        modelColors.push({
          order: 'internal',
          partCategory: component.category_part_slug,
          color: component.external_colours[0].internal[0],
        })
      }
    }

    return [...modelColors]
  }

  changeComponentFormData = (component) => {
    let formData = { ...this.state.formData }
    formData[component.category_part_slug] = component.id
    return formData
  }

  checkLayerInModel = (layer) => {
    let result = this.state.model.filter((itemModel) => {
      return itemModel.id === layer.id
    })
    return result
  }

  renderCanvas = async () => {
    try {
      const {
        widthCanvas,
        heightCanvas,
        model,
        order,
        formData,
        modelColors,
        canvas,
        mobile_window_width,
        glass_code,
      } = this.state

      canvas.clear()
      canvas.setHeight(heightCanvas)
      canvas.setWidth(widthCanvas)
      FabricObject.prototype.objectCaching = true

      console.log(canvas.isWebglSupported)
      let imageData = []

      const buildImage = async (layer, baseLayer, painting = false) => {
        try {
          const imgElement = await util.loadImage(layer.image_url, {
            crossOrigin: 'anonymous',
          })

          const scaleFactor = window.devicePixelRatio > 2 ? 0.67 : 1 // Врахування Retina

          // 1️⃣ Створюємо основне зображення Fabric.js
          let img = new FabricImage(imgElement, {
            scaleX: (widthCanvas / imgElement.width) * scaleFactor,
            scaleY: (heightCanvas / imgElement.height) * scaleFactor,
            selectable: false,
            evented: false,
          })

          const colorData = modelColors.find(
            (item) =>
              item.partCategory === layer.category_part_slug &&
              item.order === order
          )

          // 🎨 Якщо є колір і **це не текстура**, змінюємо колір текстури
          if (colorData && painting && !colorData.color.is_texture) {
            // 2️⃣ Створюємо тимчасовий canvas
            const canvasEl = document.createElement('canvas')
            const ctx = canvasEl.getContext('2d')

            canvasEl.width = imgElement.width
            canvasEl.height = imgElement.height

            // 3️⃣ Малюємо текстуру
            ctx.drawImage(imgElement, 0, 0)

            // 4️⃣ Міняємо режим змішування, щоб накласти колір, але зберегти прозорість
            ctx.globalCompositeOperation = 'source-atop'
            ctx.fillStyle = colorData.color.hex
            ctx.fillRect(0, 0, canvasEl.width, canvasEl.height)

            // 5️⃣ Повертаємо звичайний режим
            ctx.globalCompositeOperation = 'destination-over'

            // 6️⃣ Створюємо нове Fabric.js зображення з цього Canvas
            img = new FabricImage(canvasEl, {
              scaleX: (widthCanvas / imgElement.width) * scaleFactor,
              scaleY: (heightCanvas / imgElement.height) * scaleFactor,
              selectable: false,
              evented: false,
            })
          }

          // 🖼 Якщо є текстура, обробляємо її окремо
          if (colorData && painting && colorData.color.is_texture) {
            const textureElement = await util.loadImage(
              colorData.color.texture,
              {
                crossOrigin: 'anonymous',
              }
            )

            const pattern = new FabricImage(textureElement)

            const maskCanvas = new StaticCanvas('additional', {
              width: widthCanvas,
              height: heightCanvas,
              lockMovement: true,
            })

            maskCanvas.add(img)
            maskCanvas.add(pattern)
            pattern.globalCompositeOperation = 'source-in'
            maskCanvas.renderAll()

            const resultImg = maskCanvas.toDataURL('png')
            const resultElement = await util.loadImage(resultImg)

            img = new FabricImage(resultElement, {
              crossOrigin: 'Anonymous',
            })
          }

          setGroupImageData(img, layer, baseLayer)
        } catch (error) {
          console.log('Error loading image:', layer.image_url, error)
        }
      }

      const setGroupImageData = (mask, layer, baseLayer) => {
        let top = 0,
          left = 0

        if (baseLayer.is_coordinates) {
          const door = this.findLayer('door_style')
          top = door[`${baseLayer.category_part_slug}_top`] || 0
          left = door[`${baseLayer.category_part_slug}_left`] || 0
        }

        if (window.innerWidth < mobile_window_width) {
          top *= 0.275
          left *= 0.275
        }

        if (
          order === 'internal' ||
          (formData.side === 'left' && baseLayer.is_furniture)
        ) {
          mask.set('flipX', true)
          left = -left
        }

        if (baseLayer.is_furniture) {
          mask.set({ top, left })
        }

        let zIndex = layer.z_index
        if (layer.image_url.includes('texture1_front')) {
          zIndex = layer.z_index_texture1_front
        } else if (layer.image_url.includes('texture2_front')) {
          zIndex = layer.z_index_texture2_front
        } else if (layer.image_url.includes('texture1_back')) {
          zIndex = layer.z_index_texture1_back
        } else if (layer.image_url.includes('texture2_back')) {
          zIndex = layer.z_index_texture2_back
        }

        imageData.push({
          z_index: zIndex,
          img: mask,
          is_delete: layer.is_delete,
        })
      }

      const setGroup = () => {
        const sortedData = imageData
          .sort((a, b) =>
            order === 'internal' ? b.z_index - a.z_index : a.z_index - b.z_index
          )
          .filter(
            (item) =>
              item.is_delete !==
              (order === 'external' ? 'internal' : 'external')
          )

        sortedData.forEach(({ img }) => canvas.add(img))

        canvas.renderAll()
        this.setState({
          formData: { ...formData, image: canvas.toDataURL('image/png') },
          is_loader: false,
        })

        imageData = []
      }

      // Завантаження шарів
      for (const layer of model) {
        if (window.innerWidth < mobile_window_width) {
          ;[
            'image_url',
            'image_url_back',
            'texture1_back',
            'texture1_front',
            'texture2_back',
            'texture2_front',
          ].forEach((key) => {
            layer[key] = layer[`${key}_small`] || layer[key]
          })
        }

        if (layer.img === '/storage/') continue

        const isPaintingLayer = ['door_style', 'frame_style'].includes(
          layer.category_part_slug
        )

        if (!layer.type_furniture || layer.type_furniture === 'default') {
          await buildImage(layer, layer, isPaintingLayer)
        } else {
          const furnitureTypes = {
            front: () =>
              buildImage(
                { ...layer, is_delete: 'external' },
                layer,
                isPaintingLayer
              ),
            back: () =>
              buildImage(
                { ...layer, is_delete: 'internal' },
                layer,
                isPaintingLayer
              ),
            same: async () => {
              await buildImage(
                { ...layer, is_delete: 'external' },
                layer,
                isPaintingLayer
              )
              await buildImage(
                { ...layer, is_delete: 'internal' },
                layer,
                isPaintingLayer
              )
            },
            different: async () => {
              await buildImage(
                { ...layer, image_url: layer.image_url, is_delete: 'external' },
                layer,
                isPaintingLayer
              )
              await buildImage(
                {
                  ...layer,
                  image_url: layer.image_url_back,
                  is_delete: 'internal',
                },
                layer,
                isPaintingLayer
              )
            },
          }

          if (furnitureTypes[layer.type_furniture]) {
            await furnitureTypes[layer.type_furniture]()
          }
        }

        if (
          ['door_style', 'frame_style'].includes(layer.category_part_slug) &&
          !layer.is_furniture &&
          order === 'external' &&
          layer.texture2_front
        ) {
          await buildImage(
            {
              ...layer,
              image_url: layer.texture2_front,
              is_delete: 'external',
              z_index: layer.z_index_texture2_front,
            },
            layer
          )
        }
        if (
          ['door_style', 'frame_style'].includes(layer.category_part_slug) &&
          !layer.is_furniture &&
          order === 'internal' &&
          layer.texture1_back
        ) {
          await buildImage(
            {
              ...layer,
              image_url: layer.texture1_back,
              is_delete: 'internal',
              z_index: layer.z_index_texture1_back,
            },
            layer
          )
        }

        if (
          layer.category_part_slug === 'frame_style' &&
          formData.glass !== null
        ) {
          let background = model.find(
            (item) => item.category_part_slug === 'frame_style'
          )
          let background_glass = background.background_glass.find((bg) =>
            bg.glasses.includes(glass_code)
          )

          if (background_glass) {
            await buildImage(
              {
                image_url: background_glass.outside,
                z_index: 349,
                is_delete: 'external',
              },
              layer
            )
            await buildImage(
              {
                image_url: background_glass.inside,
                z_index: 631,
                is_delete: 'internal',
              },
              layer
            )
          }
        }
      }

      setGroup()
    } catch (error) {
      console.error('Error in renderCanvas:', error)
      this.setState({ is_loader: false })
    }
  }

  findColorsPart = (category) => {
    let colors = []
    let model = [...this.state.model]
    let find = model.filter((x) => x.category_part_slug === category)

    if (find.length > 0) {
      colors = [...find[0].external_colours]
    }

    return colors
  }

  findInternalColorsPart = (category) => {
    let colors = []
    let index = this.state.modelColors.findIndex(
      (colorsItem) =>
        colorsItem.partCategory === category && colorsItem.order === 'external'
    )

    if (index !== -1) {
      colors = this.state.modelColors[index].color.internal
    }
    return colors
  }

  chooseColorPart = (partCategory, color, order) => {
    let colors = [...this.state.modelColors]
    let index = colors.findIndex(
      (colorsItem) =>
        colorsItem.partCategory === partCategory && colorsItem.order === order
    )

    if (index === -1) {
      colors.push({ color, partCategory, order })
    } else {
      colors[index] = { color, partCategory, order }
    }
    let formData = this.changeComponentColorFormData(partCategory, color, order)
    this.setState({
      is_loader: true,
      modelColors: [...colors],
      formData: { ...formData },
      showImageMobile: true,
    })
  }

  changeComponentColorFormData = (partCategory, color, order) => {
    let formData = { ...this.state.formData }
    if (partCategory === 'door_style' && order === 'external') {
      formData.outside_door_color = color.id
    }
    if (partCategory === 'door_style' && order === 'internal') {
      formData.inside_door_color = color.id
    }
    if (partCategory === 'frame_style' && order === 'external') {
      formData.outside_frame_color = color.id
    }
    if (partCategory === 'frame_style' && order === 'internal') {
      formData.inside_frame_color = color.id
    }
    return formData
  }

  checkLayerInModelColor = (partCategory, color, order) => {
    let result = this.state.modelColors.filter((itemModel) => {
      return (
        itemModel.partCategory === partCategory &&
        itemModel.color.id === color.id &&
        itemModel.order === order
      )
    })

    return result
  }

  setOrder = (order) => {
    this.setState({
      order: order,
    })
  }

  getFurnitureParts = (category, parent_id) => {
    this.props.getFurnitureParts(category, parent_id).then((response) => {
      this.setState({
        furnitureParts: [...response.data],
      })
    })
  }

  changeSide = (side) => {
    {
      this.setState({ formData: { ...this.state.formData, side: side } })
    }
  }

  checkColorInModel = (category, order) => {
    let index = this.state.modelColors.findIndex(
      (item) => item.partCategory === category && item.order === order
    )
    return index !== -1
  }

  setPage = (page, direction = 'next') => {
    this.setState({
      page,
      showImageMobile: false,
      direction,
      //is_loader: true,
    })
  }

  setParts = (parts) => {
    // let defaultModel = this.getDefaultModel(parts)

    this.setState({
      parts,
      // model: [...defaultModel.model],
      // modelColors: [...defaultModel.modelColors],

      formData: {
        ...this.state.formData,
        // ...defaultModel.formData,
      },
      is_loader: false,
    })
  }
  chooseHandelBrand = (handelBrand) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          handelBrand: handelBrand.brand,
        },
        activeHandleBrand: handelBrand,
      },
      () => {
        this.loadHandlesByModal(this.state.activeHandleBrand)
      }
    )
  }

  findLayer = (category) => {
    return this.state.model.find((item) => item.category_part_slug === category)
  }

  chooseGlassDouble = (glass) => {
    let model = [...this.state.model]
    let layers = []
    if (glass.door !== null && Object.keys(glass.door).length > 0) {
      layers.push(glass.door)
    }
    if (glass.frame_top !== null && Object.keys(glass.frame_top).length > 0) {
      layers.push(glass.frame_top)
    }
    if (glass.frame_side !== null && Object.keys(glass.frame_side).length > 0) {
      layers.push(glass.frame_side)
    }

    layers.map((layer) => {
      let index = model.findIndex(
        (modelItem) => modelItem.category_part_slug === layer.category_part_slug
      )

      // layer.options = options;
      if (index === -1) {
        model.push(layer)
      } else {
        model[index] = layer
      }
    })

    this.setState({
      model: [...model],
      is_loader: true,
      formData: {
        ...this.state.formData,
        glass: glass.id,
        door_glass: !!glass.door ? glass.door.id : null,
        frame_glass_top: !!glass.frame_top ? glass.frame_top.id : null,
        frame_glass_side: !!glass.frame_side ? glass.frame_side.id : null,
      },
      showImageMobile: true,
      glass_code: glass.title,
    })
  }

  chooseFrameGlass = (glass) => {
    let model = [...this.state.model]
    let layers = []

    if (glass.frame_top !== null && Object.keys(glass.frame_top).length > 0) {
      layers.push(glass.frame_top)
    }
    if (glass.frame_side !== null && Object.keys(glass.frame_side).length > 0) {
      layers.push(glass.frame_side)
    }

    layers.map((layer) => {
      let index = model.findIndex(
        (modelItem) => modelItem.category_part_slug === layer.category_part_slug
      )

      // layer.options = options;
      if (index === -1) {
        model.push(layer)
      } else {
        model[index] = layer
      }
    })
    this.setState({
      model: [...model],
      is_loader: true,
      formData: {
        ...this.state.formData,
        frame_glass_top: !!glass.frame_top ? glass.frame_top.id : null,
        frame_glass_side: !!glass.frame_side ? glass.frame_side.id : null,
      },
    })
  }

  chooseFurnitureGroup = (group) => {
    let model = [...this.state.model]
    let layers = []

    if (group.handle !== null && Object.keys(group.handle).length > 0) {
      layers.push(group.handle)
    }
    if (group.knocker !== null && Object.keys(group.knocker).length > 0) {
      layers.push(group.knocker)
    }
    if (
      group.letterplate !== null &&
      Object.keys(group.letterplate).length > 0
    ) {
      layers.push(group.letterplate)
    }
    layers.map((layer) => {
      let index = model.findIndex(
        (modelItem) => modelItem.category_part_slug === layer.category_part_slug
      )

      // layer.options = options;
      if (index === -1) {
        model.push(layer)
      } else {
        model[index] = layer
      }
    })
    this.setState({
      model: [...model],
      is_loader: true,
      formData: {
        ...this.state.formData,
        handle: !!group.handle ? group.handle.id : null,
        letterplate: !!group.letterplate ? group.letterplate.id : null,
        knocker: !!group.knocker ? group.knocker.id : null,
      },
      showImageMobile: true,
    })
  }

  backButtonHandler = (page, direction = 'back') => {
    this.setState({
      page,
      direction,
    })
  }

  nameOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        name: e.target.value,
      },
    })
  }

  addressNumber1OnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        address_number_1: e.target.value,
      },
    })
  }

  addressNumber2OnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        address_number_2: e.target.value,
      },
    })
  }

  countyOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        county: e.target.value,
      },
    })
  }

  postcodeOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        post_code: e.target.value.toUpperCase(),
      },
    })
  }

  mobileOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        phone_number: e.target.value,
      },
    })
  }

  emailOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        email: e.target.value,
      },
    })
  }

  couponOnChangeHandler = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        coupon: e.target.value,
      },
    })
  }

  resetDesign = () => {
    let model = [...this.state.model]

    let doorGlass = model.findIndex(
      (item) => item.category_part_slug === 'door-glasses'
    )
    if (doorGlass !== -1) {
      model.splice(doorGlass, 1)
    }
    let frameTopGlass = model.findIndex(
      (item) => item.category_part_slug === 'frame-glasses-top'
    )
    if (frameTopGlass !== -1) {
      model.splice(frameTopGlass, 1)
    }
    let frameSideGlass = model.findIndex(
      (item) => item.category_part_slug === 'frame-glasses-side'
    )
    if (frameSideGlass !== -1) {
      model.splice(frameSideGlass, 1)
    }
    let knocker = model.findIndex(
      (item) => item.category_part_slug === 'furniture-type-knocker'
    )
    if (knocker !== -1) {
      model.splice(knocker, 1)
    }

    let letterplate = model.findIndex(
      (item) => item.category_part_slug === 'furniture-type-letterplate'
    )
    if (letterplate !== -1) {
      model.splice(letterplate, 1)
    }

    let wreath = model.findIndex(
      (item) => item.category_part_slug === 'furniture-wreath'
    )
    if (wreath !== -1) {
      model.splice(wreath, 1)
    }

    // // Скидання background_glass
    // let frameStyle = model.find(
    //   (item) => item.category_part_slug === 'frame_style'
    // )
    // if (frameStyle) {
    //   frameStyle.background_glass = []
    // }

    this.setState({
      model: [...model],
      modelColors: [],
    })
  }

  setSeeMyDoor = (status) => {
    this.setState({
      seeMyDoor: status,
    })
  }

  hideSeeMyDoor = () => {
    this.setState({
      seeMyDoor: false,
      activeHouse: {},
      myDoorEditor: false,
    })
  }

  setActiveHouse = (house) => {
    this.setState({
      activeHouse: house,
    })
  }

  setMyDoorEditor = (status) => {
    this.setState({
      myDoorEditor: status,
    })
  }

  downloadHouse = () => {
    this.setState({ is_loader: true }, () => {
      toPng(document.getElementById('html2png')).then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-door.png'
        link.href = dataUrl
        link.click()
        this.setState({
          is_loader: false,
        })
      })
    })
  }

  setScale = () => {
    let scale = this.state.scale
    switch (scale) {
      case 1:
        scale = 1.2
        break
      case 1.2:
        scale = 2
        break
      case 2:
        scale = 1
        break
    }
    this.setState({ scale: scale })
  }

  setBackgroundColor = () => {
    let backgroundColor = this.state.backgroundColor
    switch (backgroundColor) {
      case 'transparent':
        backgroundColor = 'brown'
        break
      case 'brown':
        backgroundColor = 'blue'
        break
      case 'blue':
        backgroundColor = 'yellow'
        break
      case 'yellow':
        backgroundColor = 'transparent'
        break
    }
    this.setState({ backgroundColor: backgroundColor })
  }

  setShowImageMobile = (status) => {
    this.setState({
      showImageMobile: status,
    })
  }

  setBrand = (brand) => {
    this.setState({
      ...this.state,
      brand: brand,
      formData: {
        ...this.state.formData,
        brand: brand,
      },
    })
  }

  browseAllFurniture = () => {
    this.setState({
      page: 'furniture-type-handle',
    })
  }

  setLoader = (status) => {
    this.setState({
      is_loader: status,
    })
  }

  setIsHandleBrandError = (status) => {
    this.setState({
      isHandleBrandError: status,
    })
  }

  setIsHandleError = (status) => {
    this.setState({
      isHandleError: status,
    })
  }

  render() {
    console.log('model', this.state.model)
    return (
      <>
        {this.state.seeMyDoor ? (
          <SeeOnMyHouse
            loadHouses={this.loadHouses}
            houses={this.state.houses}
            activeHouse={this.state.activeHouse}
            setActiveHouse={this.setActiveHouse}
            setSeeMyDoor={this.setSeeMyDoor}
            myDoorEditor={this.state.myDoorEditor}
            setMyDoorEditor={this.setMyDoorEditor}
            image={this.state.formData.image}
            downloadHouse={this.downloadHouse}
            hideSeeMyDoor={this.hideSeeMyDoor}
          />
        ) : (
          <ConsumerFlowConstructor
            model={this.state.model}
            image={this.state.formData.image}
            parts={this.state.parts}
            setParts={this.setParts}
            page={this.state.page}
            setPage={this.setPage}
            brand={this.state.brand}
            brandTitle={this.state.brandTitle}
            setBrand={this.setBrand}
            chooseComponent={this.chooseComponent}
            checkLayerInModel={this.checkLayerInModel}
            renderCanvas={this.renderCanvas}
            findColorsPart={this.findColorsPart}
            checkColorInModel={this.checkColorInModel}
            chooseColorPart={this.chooseColorPart}
            checkLayerInModelColor={this.checkLayerInModelColor}
            findInternalColorsPart={this.findInternalColorsPart}
            handle={this.state.formData.handle}
            loadHandelBrands={this.loadHandelBrands}
            handelBrands={this.state.handelBrands}
            chooseHandelBrand={this.chooseHandelBrand}
            activeHandleBrand={this.state.activeHandleBrand}
            handles={this.state.handles}
            knockers={this.state.knockers}
            loadKnockers={this.loadKnockers}
            letterplates={this.state.letterplates}
            wreaths={this.state.wreaths}
            loadWreaths={this.loadWreaths}
            loadLetterplates={this.loadLetterplates}
            loadLeftOrRight={this.loadLeftOrRight}
            loadFurnitureGroup={this.loadFurnitureGroup}
            furnitureGroups={this.state.furnitureGroups}
            setOrder={this.setOrder}
            order={this.state.order}
            side={this.state.formData.side}
            changeSide={this.changeSide}
            chooseGlassDouble={this.chooseGlassDouble}
            loadGlassByModel={this.loadGlassByModel}
            glasses={this.state.glasses}
            glassActive={this.state.formData.glass}
            backButtonHandler={this.backButtonHandler}
            nameOnChangeHandler={this.nameOnChangeHandler}
            addressNumber1OnChangeHandler={this.addressNumber1OnChangeHandler}
            addressNumber2OnChangeHandler={this.addressNumber2OnChangeHandler}
            countyOnChangeHandler={this.countyOnChangeHandler}
            postcodeOnChangeHandler={this.postcodeOnChangeHandler}
            mobileOnChangeHandler={this.mobileOnChangeHandler}
            emailOnChangeHandler={this.emailOnChangeHandler}
            couponOnChangeHandler={this.couponOnChangeHandler}
            formData={this.state.formData}
            saveOrder={this.saveOrder}
            loadRetrieveOrder={this.loadRetrieveOrder}
            loadFrameGlasses={this.loadFrameGlasses}
            frameGlasses={this.state.frameGlasses}
            chooseFrameGlass={this.chooseFrameGlass}
            findLayer={this.findLayer}
            setSeeMyDoor={this.setSeeMyDoor}
            scale={this.state.scale}
            setScale={this.setScale}
            backgroundColor={this.state.backgroundColor}
            setBackgroundColor={this.setBackgroundColor}
            navigateConstructorNav={this.navigateConstructorNav}
            showImageMobile={this.state.showImageMobile}
            setShowImageMobile={this.setShowImageMobile}
            chooseFurnitureGroup={this.chooseFurnitureGroup}
            browseAllFurniture={this.browseAllFurniture}
            setLoader={this.setLoader}
            isHandleBrandError={this.state.isHandleBrandError}
            isHandleError={this.state.isHandleError}
            setIsHandleBrandError={this.setIsHandleBrandError}
            setIsHandleError={this.setIsHandleError}
            affiliate={this.state.affiliate}
            outsideDoorColours={this.state.outsideDoorColours}
            insideDoorColours={this.state.insideDoorColours}
            outsideFrameColours={this.state.outsideFrameColours}
            insideFrameColours={this.state.insideFrameColours}
            loadOutsideDoorColors={this.loadOutsideDoorColors}
            loadInsideDoorColors={this.loadInsideDoorColors}
            loadOutsideFrameColors={this.loadOutsideFrameColors}
            loadInsideFrameColors={this.loadInsideFrameColors}
          />
        )}

        <Loader status={this.state.is_loader} />

        <canvas
          style={{ display: 'none' }}
          width={this.state.widthCanvas}
          height={this.state.heightCanvas}
          id={'canvas-builder'}
          //ref={this.canvasRef}
        />
      </>
    )
  }
}
function mapStateToProps(state) {
  return {}
}
function mapDispatchToProps(dispatch) {
  return {
    getFurnitureCategories: () => {
      return dispatch(getFurnitureCategories())
    },
    getFurnitureParts: (category, parent_id, doorStyle) => {
      return dispatch(getFurnitureParts(category, parent_id, doorStyle))
    },
    getHandelBrands: (brand, door_style) => {
      return dispatch(getHandelBrands(brand, door_style))
    },
    getHandelsByModel: (model) => {
      return dispatch(getHandelsByModel(model))
    },
    getGlassByModel: (door, frame) => {
      return dispatch(getGlassByModel(door, frame))
    },
    getFrameGlasses: (frame) => {
      return dispatch(getFrameGlasses(frame))
    },
    saveOrder: (formData, partnerPath) => {
      return dispatch(saveOrder(formData, partnerPath))
    },
    retrieveOrder: (id) => {
      return dispatch(retrieveOrder(id))
    },
    getAllHouses: () => {
      return dispatch(getAllHouses())
    },
    getPartsOfBrand: (brand) => {
      return dispatch(getPartsOfBrand(brand))
    },
    getFurnitureGroup: (brand) => {
      return dispatch(getFurnitureGroup(brand))
    },

    showAffiliate: (name) => {
      return dispatch(showAffiliate(name))
    },
    getDefaultModelBrand: (brand) => {
      return dispatch(getDefaultModelBrand(brand))
    },
    getOutsideDoorColor: (door, brand) => {
      return dispatch(getOutsideDoorColor(door, brand))
    },
    getInsideDoorColor: (outside, brand) => {
      return dispatch(getInsideDoorColor(outside, brand))
    },
    getOutsideFrameColor: (frame, brand) => {
      return dispatch(getOutsideFrameColor(frame, brand))
    },
    getInsideFrameColor: (outside, brand) => {
      return dispatch(getInsideFrameColor(outside, brand))
    },
    getDefaultColorsRequest: (door, frame, brand) => {
      return dispatch(getDefaultColorsRequest(door, frame, brand))
    },
    getBrand: (slug, affiliate) => {
      return dispatch(getBrand(slug, affiliate))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AbstractConstructor)
